/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: 'Museo Sans';
  src: url('./fonts/MuseoSansCyrl-500.eot');
  src: /* local('./fonts/MuseoSansCyrl-500'), */ url('./fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-500.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-500.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-500.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./fonts/MuseoSansCyrl-500Italic.eot');
  src: /* local('./fonts/MuseoSansCyrl-500Italic'), */ url('./fonts/MuseoSansCyrl-500Italic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-500Italic.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-500Italic.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-500Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./fonts/MuseoSansCyrl-700.eot');
  src: /* local('./fonts/MuseoSansCyrl-700'), */ url('./fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-700.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-700.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./fonts/MuseoSansCyrl-700Italic.eot');
  src: /* local('./fonts/MuseoSansCyrl-700Italic'), */ url('./fonts/MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-700Italic.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-700Italic.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-700Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Museo San';
  src: url('./fonts/MuseoSansCyrl-300Italic.eot');
  src: /* local('MuseoSansCyrl-300Italic'), */ url('./fonts/MuseoSansCyrl-300Italic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-300Italic.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-300Italic.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-300Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./fonts/MuseoSansCyrl-300.eot');
  src: /* local('./fonts/MuseoSansCyrl-300'), */ url('./fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-300.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-300.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./fonts/MuseoSansCyrl-100.eot');
  src: /* local('./fonts/MuseoSansCyrl-100'), */ url('./fonts/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-100.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-100.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-100.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./fonts/MuseoSansCyrl-100Italic.eot');
  src: /* local('./fonts/MuseoSansCyrl-100Italic'), */ url('./fonts/MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-100Italic.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-100Italic.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-100Italic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./fonts/MuseoSansCyrl-900.eot');
  src: /* local('./fonts/MuseoSansCyrl-900'), */ url('./fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-900.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-900.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-900.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('./fonts/MuseoSansCyrl-900Italic.eot');
  src: /* local('./fonts/Museo Sans Cyrl 900 Italic'), local('MuseoSansCyrl-900Italic'), */ url('./fonts/MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MuseoSansCyrl-900Italic.woff2') format('woff2'),
  url('./fonts/MuseoSansCyrl-900Italic.woff') format('woff'),
  url('./fonts/MuseoSansCyrl-900Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

body {
  font-size: 0.875rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  @apply bg-gray-100;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

a,
button:focus {
  @apply outline-0;
  outline: 0;
}

button:disabled {
  @apply opacity-40 pointer-events-none;
}

.product-buttons:active {
  @apply shadow-2xs shadow-lightblue-700/50;
}

.tab {
  @apply cursor-pointer font-medium pb-2;
}

.tab:hover {
  @apply text-black;
}

.tab.active {
  @apply border-bluegray-500 border-b;
}

.tabs {
  @apply flex justify-start;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  vertical-align: -0.125em;
}



.mobile-menu {
  @apply fixed top-0 left-0 h-full w-full;
  transform: translateX(100%);
  transition: all 0.2s;
}

.mobile-menu.activeMenu {
  transform: translateX(0);
}

.summary {
  @apply fixed top-0 right-0 h-full shadow-xl w-[432px];
  transform: translateX(100%);
  transition: all 0.2s;
}

@media screen and (max-width: 575px) {
  .summary {
    @apply w-full;
  }
}

.summary.activeSummary {
  transform: translateX(0);
}

.canvas {
  @apply relative max-w-[720px] max-h-[350px];
}

.user-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.25rem center;
  background-size: 16px 12px;
}

.page-content {
  min-height: calc(100vh - 3rem);
}

.form-user-block {
  @apply lg:w-1/2 px-3 py-3 bg-bluegray-50 rounded-md h-auto;
}

.form—company-block {
  @apply lg:w-[35%] px-3 py-3 bg-bluegray-50 rounded-md h-auto;
}

.form-company-block-second {
  @apply px-3 py-3 bg-bluegray-50 rounded-md h-auto;
}

.form-label {
  @apply block mb-2 text-sm text-gray-600;
}

.form-input {
  @apply w-full h-12 px-3 py-2 placeholder-gray-300 border rounded-md focus:outline-none;
}

.form-input-address {
  @apply w-full px-3 py-[6px] border placeholder-gray-300 rounded-md focus:outline-none;
}

.form-textarea {
  resize: none;
  @apply w-full px-3 py-2 placeholder-gray-300 border rounded-md focus:outline-none;
}

.form-error-text {
  @apply text-xs text-red-500;
}

.form-button {
  @apply w-full font-semibold text-base px-3 py-3 text-button-reports uppercase border-2 border-button-reports bg-white rounded-md hover:bg-button-reports hover:text-white focus:outline-none active:bg-button-reports active:text-white product-buttons;
}

.PhoneInputInput {
  @apply h-10 px-3 py-2 placeholder-gray-300 focus:outline-none;
}

.PhoneInputCountrySelect {
  @apply border rounded-md;
}

.react-tel-input .selected-flag {
  border-radius: 6px 0 0 6px !important;
}

.sort-button:hover select {
  @apply bg-gray-100;
}

.sort-button:hover .sort-button-dropdown {
  @apply bg-bluegray-100;
}

.form-input-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-input-valid:focus {
  @apply shadow-xs shadow-green-700/25;
}

.form-input-valid:focus-within {
  @apply shadow-xs shadow-green-700/25;
}

.form-input-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-input-invalid:focus {
  @apply shadow-xs shadow-red-700/25;
}

.form-input-invalid:focus-within {
  @apply shadow-xs shadow-red-700/25;
}

.fa-sort {
  @apply ml-2 text-bluegray-400 text-xxs;
}

th {
  @apply whitespace-nowrap py-3 px-2 text-sm tracking-wider font-semibold text-left text-bluegray-700;
}

td {
  @apply whitespace-nowrap py-3 px-2 font-medium tracking-wider  text-bluegray-600;
  font-size: 0.9rem;
}

.form-radio {
  @apply bg-white border-bluegray-300  ;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  /* color-adjust: exact; */
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  border-radius: 50%;
}

.form-radio:checked {
  background-image: url("./images/circle-solid.svg");
  border-color: transparent;
  background-color: currentColor;
  background-size: 40%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.form-radio:focus:checked {
  @apply shadow-xxs shadow-bluegray-400/50;
  outline: none;
  /* box-shadow: 0 0 0 3px rgb(164 202 254 / 45%); */
  border-color: transparent;
}

.dropdown-content {
  max-height: 300px;
}

.inputfile {
  @apply w-0 h-0 opacity-0 overflow-hidden absolute;
  z-index: -1;
}

.margin-l-r-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.settingTab {
  @apply flex cursor-pointer rounded-md items-center pl-3 pr-3 py-2 hover:text-bluegray-900;
}

.activeSettingTab {
  @apply bg-bluegray-100 text-bluegray-700;
}

/*
div::-webkit-scrollbar {
  background-color: #fff;
  width: 12px;
  height: 12px;
}

div::-webkit-scrollbar-track {
  background-color: inherit;
}
div:hover::-webkit-scrollbar-track {
  background-color: #fff;
}

div::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 16px;
  border: 3px solid inherit;
  -webkit-transition: border 2s;
  transition: border 2s;
}

div::-webkit-scrollbar-button {
  display: none;
}

div:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  border: 3px solid #fff;
}

div::-webkit-scrollbar-thumb:hover {
  border: 2px solid #fff;
} */

.beacon-hidden {
  @apply hidden;
}

.disableArrows > input::-webkit-outer-spin-button,
.disableArrows > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  @apply m-0;
}

.disableArrows > input[type=number] {
  -moz-appearance: textfield;
}

.DraftEditor-root {
  @apply h-full px-1.5;
}

.public-DraftStyleDefault-block {
  @apply m-0;
}

.DraftEditor-editorContainer {
  @apply h-full;
}

.switchRadioBtn {
  @apply relative inline-block;
  width: 65px;
  height: 32px;
}

/* Hide default HTML checkbox */
.switchRadioBtn input {
  @apply w-0 h-0 opacity-0;
}

/* The slider */
.sliderSwitchRadioBtn {
  @apply bg-bluegray-400 absolute cursor-pointer inset-0 transition-transform duration-300 ease-linear;
}

.sliderSwitchRadioBtn:before {
  @apply bg-white absolute transition-transform duration-300 ease-linear;
  content: "";
  height: 25px;
  width: 25px;
  left: 4px;
  bottom: 4px;
}

input:checked + .sliderSwitchRadioBtn {
  @apply bg-green-800;
}

input:checked + .sliderSwitchRadioBtn:before {
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
}

/* Rounded sliders */
.sliderSwitchRadioBtn.roundSwitchRadioBtn {
  border-radius: 34px;
}

.sliderSwitchRadioBtn.roundSwitchRadioBtn:before {
  border-radius: 50%;
}

/* @media screen and (min-width: 1024px) {
  .dashboard-tenders {
    width: 74% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .dashboard-tenders {
    width: 65% !important;
  }
} */

.buy-button {
  @apply bg-emerald-50 border-emerald-500 border-opacity-20 border text-emerald-900 hover:bg-emerald-200 hover:bg-opacity-50  cursor-pointer p-1 px-3  rounded  text-center;
}

.bid-button {
  @apply bg-lightblue-50 border-lightblue-500 border-opacity-20 border text-lightblue-900 hover:bg-lightblue-200 hover:bg-opacity-50  cursor-pointer p-1 px-3  rounded  text-center;
}

.delete-bid-button {
  @apply bg-red-50 border-red-500 border-opacity-20 border text-red-900 hover:bg-red-200 hover:bg-opacity-50  cursor-pointer p-1 px-3  rounded  text-center;
}


.green-status {
  @apply bg-emerald-50 bg-opacity-20  border-emerald-500 border-opacity-30 border text-emerald-700;
}

.blue-status {
  @apply bg-lightblue-50 bg-opacity-20  border-lightblue-500 border-opacity-30 border text-lightblue-700;
}

.red-status {
  @apply bg-red-50 bg-opacity-20  border-red-500 border-opacity-20 border text-red-700;
}

.yellow-status {
  @apply bg-yellow-50 bg-opacity-20  border-yellow-500 border-opacity-20 border text-yellow-500;
}

.gray-status {
  @apply bg-bluegray-50 bg-opacity-20  border-bluegray-500 border-opacity-20 border text-bluegray-700;
}

.gallery_tr {
  @apply hover:bg-bluegray-100
}

.gallery_td {
  @apply px-5 py-1 text-sm text-bluegray-600 border border-bluegray-100 tracking-normal
}

.popup-body {
  z-index: 100000;
}

.tender_results-td-bid {
  min-width: 180px;
}

@media only screen and (min-width: 1024px) {
  .lgh-90 {
    height: 26.8em
  }

  .lg-minh-90 {
    min-height: 26.8em
  }

  .lg-w-65 {
    width: 65vw;
  }
}

@media only screen and (min-width: 1200px) {
  .desktop-ml-50vw {
    margin-left: 50%;
  }
}

.xzoom-preview {
  z-index: 999;
}

.xzoom-source {
  z-index: 999;
}

.zoomtracker {
  /*z-index: 0 !important;*/
  /*height: 262px !important;*/
}

.jodit-container:not(.jodit_inline) {
  @apply border-bluegray-200
}

.editorStyle {
  @apply bg-white
}

.editorStyle ul {
  list-style-type: disc;
  list-style-position: inside;
}

.editorStyle ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.BeaconFabButtonFrame {
  display: none !important;
  bottom: 55px !important;
  z-index: 100 !important;
}


.screen-ios {
  @apply inset-0 min-h-screen h-auto;
}

#itemPopup {
  @apply h-full;
}

.input-info::after {
  content: '';
  position: absolute;
  left: 45%;
  bottom: -10px;
  border: 5px solid transparent;
  border-top: 5px solid rgba(252, 165, 165, 1);
}

/* #slideImage {
  touch-action: pan-y !important;
}

#slideImageJewelry {
  touch-action: pan-y !important;
} */

.form-input:disabled {
  @apply bg-gray-300 opacity-30;
}

.table-shadows {
  @apply bg-no-repeat bg-white;
  background-image: linear-gradient(to right, white, white),
  linear-gradient(to right, white, white),
  linear-gradient(to right, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0)),
  linear-gradient(to left, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

.form-check-input:checked[type=checkbox] {
  background-image: url(data:image/svg+xml,%3csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 20 20' %3e%3cpath fill= 'none' stroke= '%23fff' stroke-linecap= 'round' stroke-linejoin= 'round' stroke-width= '3' d= 'M6 10l3 3l6-6' /%3e%3c/svg%3e);
}

.tender-results-link {
  @apply hover:text-lightblue-800 underline text-lightblue-600 font-semibold;
}

.fsp-picker--inline {
  @apply z-10;
  min-width: 260px !important;
}

.settingTabGlobal {
  @apply pb-2 cursor-pointer z-10 hover:text-lightblue-700 whitespace-nowrap
}

.settingTabGlobalSelected {
  @apply border-lightblue-600 border-b-2 text-lightblue-600
}

.tridi-control-bar:first-child {
  display: none !important;
}

.fsp-button--authgoogle svg {
  display: inline !important;
}

.additionalDetaillsField {
  @apply flex space-x-2 items-center border-b border-bluegray-100 w-full mobile:w-8/12 md:w-10/12 lg:w-8/12 py-0.5;
}

.fsp-button:hover {
  opacity: 1 !important;
  @apply bg-opacity-80 bg-[#2e68fb];
}

.video-grabbing {
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
}
.list-style-success {
  @apply text-green-600;
  list-style-type: "✓ ";
}

.list-style-error {
  @apply text-red-600;
  list-style-type: "×  ";
}

.collapsible-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 0;
  background-color: white;
}

.collapsible-content.expanded {
  max-height: 1000px;
  border-width: 1px 1px 1px 1px;
  border-color: #ccc;
  border-style: solid;
  padding: 10px 10px 10px 10px;
}

.address-list-container {
  max-height: 620px;
  overflow-y: scroll;
  scrollbar-width: none; 
  -ms-overflow-style: none;
}

.transition-width {
  transition: max-width 0.3s ease-in-out;
  overflow: hidden;
  max-width: 0;
}

.transition-width.open {
  max-width: 100%;
}

.notVisibleTest {
  transform: translateX(-100%);
  opacity: 0;
}

.notVisible {
 opacity: 0;
}

.visible {
  opacity: 1;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 6px;
  pointer-events: none;
}

.svg-inline--fa-search {
  display: inline-block;
  font-size: inherit;
  vertical-align: -7px;
  opacity: 0.7;
}

.custom-tooltip {
  background-color: #940b0b; 
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  padding: 5px 5px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 125%;
  /* left: 50%; */
  right: -55%;
  background-color: white;
  border: 1px solid gray;
  color: black;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 60px;
  white-space: nowrap;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 2%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #403e3ed1 transparent transparent transparent;
}


/* .custom-tooltip{
  position: absolute;
  top: 125%;
  left: -48px;
  background-color: white;
  border: 1px solid gray;
  color: black;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 60px;
} */

/* .custom-tooltip::after{
  content: '';
  position: absolute;
  top: -11px;
  left: 56px;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #808080 transparent;
  z-index: 11;
} */

/* @media screen and (min-width: 1024px){
  .custom-tooltip{
    position: absolute;
    top: 125%;
    left: auto;
    right: -300px;
    background-color: white;
    border: 1px solid gray;
    color: black;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    min-width: 60px;
    white-space: nowrap;
  } */
  /* .custom-tooltip-desktop::after {
    content: '';
    position: absolute;
    top: -11px;
    left: auto;
    right: 297px;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #808080 transparent;
    z-index: 11;
  }
} */

/* @media screen and (min-width: 768px){
  .custom-tooltip{
    white-space: nowrap;
  }
} */

.custom-tooltip {
  position: absolute;
  top: 125%;
  left: -48px;
  background-color: white;
  border: 1px solid gray;
  color: black;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 60px;
}

.custom-tooltip::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 56px;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #808080 transparent;
  z-index: 11;
}

@media screen and (min-width: 1024px) {
  .custom-tooltip {
    left: 50%; 
    transform: translateX(-50%);
  }

  .custom-tooltip::after {
    left: 50%; 
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 768px) {
  .custom-tooltip {
    white-space: nowrap;
  }
}

.refresh-text-button {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s;
  padding: 0;
}

.refresh-text-button:hover:not(:disabled) {
  color: #1d53aa;
}

.refresh-text-button:disabled {
  color: #9ca3af;
  cursor: not-allowed;
}
